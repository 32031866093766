import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import FoundationIcon from "@mui/icons-material/Foundation";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BlockIcon from "@mui/icons-material/Block";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import EventNoteIcon from "@mui/icons-material/EventNote";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MapIcon from "@mui/icons-material/Map";
import QuizIcon from "@mui/icons-material/Quiz";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { Box, Chip, Divider } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppBar, Layout, Menu, UserMenu, useDataProvider } from "react-admin";
import { AccessContext } from "../../../containers/access-provider";
import { AuthContext } from "../../../lib/contexts";
import LogoutButton from "../LogoutButton";
import { LiveHelp } from "@mui/icons-material";
import {
  REACT_APP_ADMIN_EMAIL,
  REACT_APP_MEDIA_ADMIN_EMAIL,
} from "../../../constants/constants";
import { useQuery } from "@apollo/client";
import { GET_VOLONTIER_GROUP } from "../../../lib/raQueries";
import { getAmountOfRequests } from "../../../utils/getAmountOfRequests";
import { useLocation } from "react-router-dom";
import { useActions, useAppSelector } from "../../../store/storeHooks";

import styles from "./MenuLayout.module.css";
import { getMaxChipLength } from "../../../utils/getMaxChipLength";

const CustomUserMenu = () => (
  <UserMenu>
    <LogoutButton />
  </UserMenu>
);

const CustomAppBar = () => (
  <AppBar userMenu={<CustomUserMenu />} sx={{ backgroundColor: "#703eff" }} />
);

const CustomDivider = () => <Box sx={{ my: 1 }} />;

const CustomMenu = () => {
  const dataProvider = useDataProvider();
  const { setAccesses } = useContext(AccessContext);
  const { currentUser } = useContext(AuthContext);
  const [customAccesses, setCustomAccesses] = useState([]);
  const [loading, setLoading] = useState(true);

  const { setVoluntierRequests, setConnectGroupRequests } = useActions();
  const { voluntier, connectRequest } = useAppSelector(
    (state) => state.userRequests
  );

  useEffect(() => {
    dataProvider
      .getList("volontier_group", {
        filter: {},
        meta: { cache: false },
      })
      .then((res) => setVoluntierRequests(getAmountOfRequests(res.data)));
    dataProvider
      .getList("connect_group", {
        filter: {},
        meta: { cache: false },
      })
      .then((res) => setConnectGroupRequests(getAmountOfRequests(res.data)));
  }, []);

  useEffect(() => {
    if (
      dataProvider &&
      dataProvider.getList &&
      dataProvider.getMany &&
      currentUser
    ) {
      dataProvider
        ?.getList("roles", {})
        .then(({ data }) => {
          if (data) {
            const tables_ids = [];

            for (const role of data) {
              // TODO: Return on production version.!
              //role.role_name_id === currentUser.role_id
              if (role.role_name_id) {
                tables_ids.push(role.table_id);
              }
            }
            console.log("tables_ids form CustomMenu: ", tables_ids);

            dataProvider
              ?.getMany("tables_names", { ids: tables_ids })
              .then((res) => {
                console.log("res.data form CustomMenu: ", res.data);
                let new_accesses = [];
                for (const table of res.data) {
                  new_accesses[table.name] = true;
                }
                setCustomAccesses(new_accesses);
                setAccesses(new_accesses);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [currentUser]);

  if (
    loading ||
    typeof dataProvider.getList !== "function" ||
    typeof dataProvider.getMany !== "function"
  ) {
    return (
      <Menu>
        <Menu.DashboardItem
          sx={{ whiteSpace: "normal" }}
          primaryText="Завантаження..."
        />
      </Menu>
    );
  }

  return (
    <Menu>
      <Menu.DashboardItem sx={{ whiteSpace: "normal" }} primaryText="Головна" />

      <CustomDivider />

      {currentUser.email === REACT_APP_MEDIA_ADMIN_EMAIL ? (
        <>
          {(customAccesses["videos"] || customAccesses["all"]) && (
            <Menu.Item
              to="/videos"
              primaryText="Ефіри та записи"
              leftIcon={<VideoSettingsIcon />}
            />
          )}

          {(customAccesses["filters"] || customAccesses["all"]) && (
            <Menu.Item
              to="/filters"
              primaryText="Фільтри"
              leftIcon={<FilterAltIcon />}
            />
          )}

          {(customAccesses["access_level"] || customAccesses["all"]) && (
            <Menu.Item
              to="/access_level"
              primaryText="Рівень доступу до контенту"
              leftIcon={<TheaterComedyIcon />}
            />
          )}
        </>
      ) : currentUser.email === REACT_APP_ADMIN_EMAIL ? (
        <>
          {(customAccesses["events"] || customAccesses["all"]) && (
            <Menu.Item
              to="/events"
              primaryText="Події церкви"
              leftIcon={<EventNoteIcon />}
            />
          )}

          {(customAccesses["videos"] || customAccesses["all"]) && (
            <Menu.Item
              to="/videos"
              primaryText="Ефіри та записи"
              leftIcon={<VideoSettingsIcon />}
            />
          )}

          {(customAccesses["filters"] || customAccesses["all"]) && (
            <Menu.Item
              to="/filters"
              primaryText="Фільтри"
              leftIcon={<FilterAltIcon />}
            />
          )}
        </>
      ) : (
        <>
          {(customAccesses["users"] || customAccesses["all"]) && (
            <Menu.Item
              to="/users"
              primaryText="Користувачі"
              leftIcon={<AccountCircleIcon />}
            />
          )}

          {(customAccesses["white_list_words"] || customAccesses["all"]) && (
            <Menu.Item
              to="/white_list_words"
              primaryText="Дозволені слова" /* "Словник слів" */
              leftIcon={
                <svg
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.1055 15.9271C17.9887 15.9271 21.1367 12.7791 21.1367 8.89587C21.1367 5.01262 17.9887 1.86462 14.1055 1.86462C10.2222 1.86462 7.07422 5.01262 7.07422 8.89587C7.07422 12.7791 10.2222 15.9271 14.1055 15.9271Z"
                    stroke="#737373"
                    stroke-width="2"
                  />
                  <path
                    d="M10.2635 14.8204L4.339 20.7449C3.76374 21.3201 2.83092 21.3201 2.25566 20.7449C1.6804 20.1696 1.6804 19.2368 2.25566 18.6615L8.04993 12.8673M10.9796 7.073L12.5421 11.2397L14.1046 7.073L15.6671 11.2397L17.2296 7.073"
                    stroke="#737373"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
            />
          )}

          {(customAccesses["foundations"] || customAccesses["all"]) && (
            <Menu.Item
              to="/foundations"
              primaryText="Пожертви"
              leftIcon={<FoundationIcon />}
            />
          )}

          {(customAccesses["events"] || customAccesses["all"]) && (
            <Menu.Item
              to="/events"
              primaryText="Події церкви"
              leftIcon={<EventNoteIcon />}
            />
          )}

          {(customAccesses["videos"] || customAccesses["all"]) && (
            <Menu.Item
              to="/videos"
              primaryText="Ефіри та записи"
              leftIcon={<VideoSettingsIcon />}
            />
          )}

          {(customAccesses["filters"] || customAccesses["all"]) && (
            <Menu.Item
              to="/filters"
              primaryText="Фільтри"
              leftIcon={<FilterAltIcon />}
            />
          )}

          {(customAccesses["meetings"] || customAccesses["all"]) && (
            <Menu.Item
              to="/meetings"
              primaryText="Недільні зустрічі"
              leftIcon={<MapIcon />}
            />
          )}

          {(customAccesses["connect_group"] ||
            customAccesses["connect_group_list"] ||
            customAccesses["all"]) && <Divider />}

          {(customAccesses["connect_group"] || customAccesses["all"]) && (
            <Menu.Item
              to="/connect_group"
              sx={{ whiteSpace: "normal" }}
              primaryText={
                <>
                  Заявки до спільноти
                  {!!connectRequest && (
                    <Chip
                      className={styles.itemChip}
                      label={getMaxChipLength(connectRequest)}
                    />
                  )}
                </>
              }
              leftIcon={<ConnectWithoutContactIcon />}
            />
          )}

          {(customAccesses["connect_group_list"] || customAccesses["all"]) && (
            <Menu.Item
              to="/connect_group_list"
              primaryText="Спільноти"
              leftIcon={<ConnectWithoutContactIcon />}
            />
          )}

          {(customAccesses["volontier_group"] || customAccesses["all"]) && (
            <Divider />
          )}

          {(customAccesses["volontier_group"] || customAccesses["all"]) && (
            <Menu.Item
              to="/volontier_group"
              sx={{ whiteSpace: "normal" }}
              primaryText={
                <>
                  Заявки до волонтерської групи
                  {!!voluntier && (
                    <Chip
                      className={styles.itemChip}
                      label={getMaxChipLength(voluntier)}
                    />
                  )}
                </>
              }
              leftIcon={<EscalatorWarningIcon />}
            />
          )}

          {(customAccesses["volunteer_teams"] || customAccesses["all"]) && (
            <Menu.Item
              to="/volunteer_teams"
              sx={{ whiteSpace: "normal" }}
              primaryText="Команди волонтерів"
              leftIcon={<EscalatorWarningIcon />}
            />
          )}

          {(customAccesses["prayers"] || customAccesses["all"]) && <Divider />}

          {(customAccesses["prayers"] || customAccesses["all"]) && (
            <Menu.Item
              to="/prayers"
              sx={{ whiteSpace: "normal" }}
              primaryText="Модерація Молитовної стіни"
              leftIcon={<VolunteerActivismIcon />}
            />
          )}

          {(customAccesses["alerts_to_coach"] || customAccesses["all"]) && (
            <Divider />
          )}

          {(customAccesses["alerts_to_coach"] || customAccesses["all"]) && (
            <Menu.Item
              to="/alerts_to_coach"
              primaryText="Заявки до Лідерів"
              leftIcon={<SupervisorAccountIcon />}
            />
          )}

          {(customAccesses["roles"] ||
            customAccesses["roles_names"] ||
            customAccesses["all"]) && <Divider />}

          {(customAccesses["roles"] || customAccesses["all"]) && (
            <Menu.Item
              to="/roles"
              primaryText="Ролі"
              leftIcon={<TheaterComedyIcon />}
            />
          )}

          {(customAccesses["access_level"] || customAccesses["all"]) && (
            <Menu.Item
              to="/access_level"
              primaryText="Рівень доступу до контенту"
              leftIcon={<TheaterComedyIcon />}
            />
          )}

          {(customAccesses["roles_names"] || customAccesses["all"]) && (
            <Menu.Item
              to="/roles_names"
              primaryText="Назви ролей"
              leftIcon={<AssignmentIndIcon />}
            />
          )}

          {(customAccesses["infostand"] || customAccesses["all"]) && (
            <Divider />
          )}

          {(customAccesses["infostand"] || customAccesses["all"]) && (
            <Menu.Item
              to="/infostand"
              primaryText="Інфостенд"
              leftIcon={<QuizIcon />}
            />
          )}

          {(customAccesses["analytics"] || customAccesses["all"]) && (
            <Menu.Item
              to="/analytics"
              primaryText="Аналітика"
              leftIcon={<AssessmentIcon />}
            />
          )}

          {(customAccesses["meetings_requests"] || customAccesses["all"]) && (
            <Divider />
          )}

          {(customAccesses["meetings_requests"] || customAccesses["all"]) && (
            <Menu.Item
              to="/meetings_requests"
              sx={{ whiteSpace: "normal" }}
              primaryText="Реєстрація на недільні зустрічі"
              leftIcon={<AddLocationAltIcon />}
            />
          )}

          {(customAccesses["ban_requests"] || customAccesses["all"]) && (
            <Divider />
          )}

          {(customAccesses["ban_requests"] || customAccesses["all"]) && (
            <Menu.Item
              to="/ban_requests"
              sx={{ whiteSpace: "normal" }}
              primaryText="Заявки на відновлення обликового запису"
              leftIcon={<BlockIcon />}
            />
          )}

          {(customAccesses["faq"] || customAccesses["all"]) && <Divider />}

          {(customAccesses["faq"] || customAccesses["all"]) && (
            <Menu.Item
              to="/faq"
              sx={{ whiteSpace: "normal" }}
              primaryText="Питання та відповіді"
              leftIcon={<LiveHelp />}
            />
          )}
        </>
      )}
    </Menu>
  );
};

const CustomLayout = (props) => (
  <Layout {...props} appBar={CustomAppBar} menu={CustomMenu} />
);

export default CustomLayout;
