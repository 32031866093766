import { ReactNode } from "react";
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography, Link } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type FaqItem = {
	question: string;
	details: ReactNode;
};

const faqData: FaqItem[] = [
	{
		question: 'Як користуватись платформою?',
		details: 'Тут можна знайти інструкції щодо використання платформи...'
	},
	{
		question: 'Як створити акаунт?',
		details: 'Щоб створити акаунт, перейдіть на сторінку реєстрації і заповніть необхідні дані...'
	},
	{
		question: 'Як відновити пароль?',
		details: (
			<>
				Для відновлення паролю натисніть <Link href="/test">"Забули пароль"</Link> на сторінці входу та дотримуйтесь інструкцій...
			</>
		)
	}
];

const FaqList = () => {
	return (
		<Box sx={{ padding: 3 }}>
			<Typography variant="h5" gutterBottom>
				Часті питання (FAQ)
			</Typography>

			{faqData.map((faq, index) => (
				<Accordion key={index}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography>{faq.question}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>{faq.details}</Typography>
					</AccordionDetails>
				</Accordion>
			))}

			<Box mt={4}>
				<Typography variant="h6" gutterBottom>
					Посилання на документацію
				</Typography>
				<Box>
					<Link href="https://docs.google.com/document/d/1KwM2cGvT0b6eozev-fYT2Fo0HGUEKcXtSEpe6rndjVI/edit#heading=h.u8xg936rl8ck" target="_blank" rel="noopener">
						Технічний опис платформи
					</Link>
				</Box>
				<Box>
					<Link href="https://docs.google.com/spreadsheets/d/19BOZ-ZnQ0fDAvG8wmWv0KBBHaKr9sPzB94sACkHftmw/edit?gid=1181991222#gid=1181991222" target="_blank" rel="noopener">
						Переклади платформи
					</Link>
				</Box>
			</Box>
		</Box>
	);
};

export default FaqList;
