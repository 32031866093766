import { Box, Button as MuiButton, Modal, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import {
  DeleteButton,
  EditButton,
  DeleteWithConfirmButton,
  useRecordContext,
  useResourceContext,
  TextInput,
  required,
} from "react-admin";
import { deleteComment, updateComment } from "../../lib/firebase";

const PrayerCommentsPreferenceButtons: FC<{
  label: string;
  prayerID?: string;
  comments?: any[];
  setComments?: (comments: any) => void;
  switchF?: () => void;
  disableEdit?: boolean;
  disableDelete?: boolean;
}> = ({
  label,
  prayerID,
  comments,
  setComments,
  switchF,
  disableEdit,
  disableDelete,
}) => {
  const [open, setOpen] = useState(false);
  //console.log("prayerID from PrayerCommentsPreferenceButtons: ", prayerID);
  const context = useRecordContext();
  const [comment, setComment] = useState(context.text);
  //console.log("context from PrayerCommentsPreferenceButtons: ", context);
  // console.log("comment from PrayerCommentsPreferenceButtons: ", comment);

  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredL, setIsHoveredL] = useState(false);
  const [isHoveredR, setIsHoveredR] = useState(false);

  useEffect(() => {
    setComment(context.text);
  }, [context]);

  return (
    <>
      <Modal
        sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box
          sx={{
            minWidth: "500px",
            maxWidth: "100%",
            minHeight: "200px",
            maxHeight: "100%",
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
            overflow: "auto",
          }}
        >
          <Typography variant="h6">Редагувати коментар</Typography>
          <div style={{ width: "100%", position: "relative" }}>
            <label
              htmlFor="text"
              style={{
                width: "100%",
                position: "absolute",
                color: "#626262",
                top: "6px",
                left: "6px",
                fontSize: "12px",
                fontFamily: "arial,sans-serif",
              }}
            >
              <span>
                Текст коментаря<span aria-hidden="true"> *</span>
              </span>
            </label>
            <div style={{ width: "100%" }}>
              <textarea
                style={{
                  width: "100%",
                  padding: "24px 10px 10px 10px",
                  backgroundColor: "#f5f5f5",
                  border: 0,
                  borderRadius: "8px",
                  color: "black",
                  fontSize: "16px",
                }}
                id="text"
                name="text"
                value={comment}
                onChange={(event) => setComment(event.target.value)}
                rows={5}
              ></textarea>
            </div>
          </div>

          <div
            role="toolbar"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              fontSize: "16px",
              fontFamily: "arial,sans-serif",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#1976d2",
                gap: "8px",
                cursor: "pointer",
                backgroundColor: isHoveredL ? "#d1e6fa" : "",
                padding: "8px",
                borderRadius: "8px",
              }}
              onClick={() => {
                if (prayerID) {
                  updateComment(prayerID, String(context.id), comment);
                  setOpen(false);
                  if (comments) {
                    const newComments = comments.map((item) => {
                      if (item.id === context.id) {
                        return { ...item, text: comment };
                      }
                      return item;
                    });
                    console.log(
                      "newComments from PrayerCommentsPreferenceButtons: ",
                      newComments
                    );

                    setComments?.(newComments);
                  }
                  //for refetchn just in case
                  //switchF?.();
                }
              }}
              onMouseEnter={() => setIsHoveredL(true)}
              onMouseLeave={() => setIsHoveredL(false)}
            >
              <svg
                width={18}
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="SaveIcon"
                fill={"#1976d2"}
              >
                <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"></path>
              </svg>
              <div>ЗБЕРЕГТИ</div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "rgb(211, 47, 47)",
                gap: "8px",
                cursor: "pointer",
                backgroundColor: isHoveredR ? "#fbeaea" : "",
                padding: "8px",
                borderRadius: "8px",
              }}
              onClick={() => {
                if (prayerID) {
                  deleteComment(prayerID, String(context.id));
                }
              }}
              onMouseEnter={() => setIsHoveredR(true)}
              onMouseLeave={() => setIsHoveredR(false)}
            >
              <svg
                width={18}
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="DeleteIcon"
                fill={"rgb(211, 47, 47)"}
              >
                <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
              </svg>
              <div> ВИДАЛИТИ</div>
            </div>
          </div>
        </Box>
      </Modal>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap={2}
      >
        <Box
          display="flex"
          alignItems="start"
          flexDirection="column"
          gap={"4px"}
        >
          {disableEdit ? (
            <></>
          ) : (
            <EditButton
              label="Редагувати"
              onClick={(ev) => {
                ev.preventDefault();
                setOpen(true);
              }}
            />
          )}

          {disableDelete ? (
            <></>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "rgb(211, 47, 47)",
                gap: "8px",
                cursor: "pointer",
                backgroundColor: isHovered ? "#e2dbdb" : "",
                padding: "3px",
              }}
              onClick={() => {
                if (prayerID) {
                  deleteComment(prayerID, String(context.id));
                }
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <svg
                width={18}
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="DeleteIcon"
                fill={"rgb(211, 47, 47)"}
              >
                <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
              </svg>
              <div> ВИДАЛИТИ</div>
            </div>
          )}
        </Box>
      </Box>
    </>
  );
};

export default PrayerCommentsPreferenceButtons;
