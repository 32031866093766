import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type UserRequestsProps = {
	voluntier: number;
	connectRequest: number;
}

const initialState: UserRequestsProps = {
	voluntier: 0,
	connectRequest: 0,
};

export const userRequestsSlice = createSlice({
	name: "videos",
	initialState,
	reducers: {
		setVoluntierRequests: (state, action: PayloadAction<number>) => {
			state.voluntier = action.payload;
		},
		setConnectGroupRequests: (state, action: PayloadAction<number>) => {
			state.connectRequest = action.payload;
		},
	},
});
export const {actions: userRequestsActions, reducer: userRequestsReducer} = userRequestsSlice;
