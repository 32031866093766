import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../lib/contexts";
import AdminPanel from "../../pages/adminPanel";
import {
  REACT_APP_ADMIN_EMAIL,
  REACT_APP_MEDIA_ADMIN_EMAIL,
} from "../../constants/constants";

const restrictedPaths = [
  "/users",
  "/foundations",
  "/meetings",
  "/connect_group",
  "/connect_group_list",
  "/volontier_group",
  "/volunteer_teams",
  "/prayers",
  "/alerts_to_coach",
  "/roles",
  "/access_level",
  "/roles_names",
  "/infostand",
  "/analytics",
  "/meetings_requests",
  "/ban_requests",
  "/faq",
];

const restrictedPathsForMediaAdmin = [
  "/users",
  "/foundations",
  "/events",
  "/meetings",
  "/connect_group",
  "/connect_group_list",
  "/volontier_group",
  "/volunteer_teams",
  "/prayers",
  "/alerts_to_coach",
  "/roles",
  "/roles_names",
  "/infostand",
  "/analytics",
  "/meetings_requests",
  "/ban_requests",
  "/faq",
];

const PrivateRoute = () => {
  const { pathname } = useLocation();
  const { currentUser } = useContext(AuthContext);

  const isRestrictedPath = restrictedPaths.some((path) =>
    pathname.startsWith(path)
  );
  const isAdmin = currentUser?.email === REACT_APP_ADMIN_EMAIL;

  const isRestrictedPathForMediaAdmin = restrictedPathsForMediaAdmin.some(
    (path) => pathname.startsWith(path)
  );
  const isMediaAdmin = currentUser?.email === REACT_APP_MEDIA_ADMIN_EMAIL;

  if (
    (isRestrictedPath && isAdmin) ||
    (isRestrictedPathForMediaAdmin && isMediaAdmin)
  ) {
    return <Navigate to="/" replace />;
  }

  return currentUser ? <AdminPanel /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
