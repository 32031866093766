import { useState } from "react";
import Cropper from "react-easy-crop";
import { Box, Button as MuiButton, Modal, Typography, RadioGroup, FormControlLabel, Radio, CircularProgress } from "@mui/material";

type Props = {
	open: boolean;
	setOpen: (open: boolean) => void;
	selectedFile: Blob | null;
	setBlob?: (blob: Blob | null) => void;
	setVideo?: (video: any) => void;
	language?: "uk" | "en";
};

export const ImageCropperModal = (props: Props) => {
	const { open, setOpen, selectedFile, setBlob, setVideo, language } = props;

	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
	const [aspectRatio, setAspectRatio] = useState(16 / 9);
	const [loading, setLoading] = useState(false);

	const onCropComplete = (_croppedArea: any, croppedAreaPixels: any) => {
		setCroppedAreaPixels(croppedAreaPixels);
	};

	const getCroppedImg = async () => {
		if (!selectedFile || !croppedAreaPixels) return null;

		const image = await createImage(URL.createObjectURL(selectedFile));
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");

		if (!ctx) {
			console.error("Canvas context is not available.");
			return null;
		}

		const { width, height, x, y } = croppedAreaPixels;

		canvas.width = width;
		canvas.height = height;

		ctx.drawImage(image, x, y, width, height, 0, 0, width, height);

		return new Promise<Blob | null>((resolve) => {
			canvas.toBlob((blob) => resolve(blob), "image/jpeg", 1);
		});
	};

	const createImage = (url: string): Promise<HTMLImageElement> => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = url;
			img.onload = () => resolve(img);
			img.onerror = (error) => reject(error);
		});
	};

	const handleSave = async () => {
		setLoading(true);

		const blob = (await getCroppedImg()) as Blob;
		if (blob && setBlob) {
			setBlob(blob);
			setOpen(false);
		}

		if (blob && setVideo) {
			const videoData =
				language === "uk"
					? { snippet: URL.createObjectURL(blob), snippetBlob: blob }
					: { snippetEN: URL.createObjectURL(blob), snippetBlobEN: blob };
			setVideo((prev: any) => ({ ...prev, ...videoData }));
			setOpen(false);
		}

		setLoading(false);
	};

	return (
		<Modal
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
			open={open}
			onClose={() => setOpen(false)}
		>
			<Box
				sx={{
					width: "calc(100vw - 32px)",
					height: "calc(100vh - 32px)",
					bgcolor: "background.paper",
					borderRadius: 2,
					boxShadow: 24,
					overflow: "hidden",
					display: "flex",
					flexDirection: "column",
					p: 2,
				}}
			>
				<Typography variant="h5" textAlign="center" mb={2}>
					Обрізати зображення
				</Typography>

				<Box sx={{ mb: 2 }}>
					<Typography variant="body2" gutterBottom>
						Виберіть співвідношення сторін:
					</Typography>
					<RadioGroup
						row
						value={aspectRatio}
						onChange={(e) => setAspectRatio(Number(e.target.value))}
					>
						<FormControlLabel value={16 / 9} control={<Radio />} label="16:9" />
						<FormControlLabel value={4 / 3} control={<Radio />} label="4:3" />
						<FormControlLabel value={1} control={<Radio />} label="1:1" />
						<FormControlLabel value={21 / 9} control={<Radio />} label="21:9" />
					</RadioGroup>
				</Box>

				<Box
					sx={{
						flexGrow: 1,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						position: "relative",
					}}
				>
					{selectedFile && (
						<Cropper
							image={URL.createObjectURL(selectedFile)}
							crop={crop}
							zoom={zoom}
							aspect={aspectRatio}
							onCropChange={setCrop}
							onZoomChange={setZoom}
							onCropComplete={onCropComplete}
						/>
					)}
				</Box>

				<MuiButton
					onClick={handleSave}
					variant="contained"
					color="primary"
					disabled={loading}
					sx={{
						position: "relative",
					}}
				>
					{loading && (
						<CircularProgress
							size={24}
							sx={{
								position: "absolute",
								left: "50%",
								top: "50%",
								marginLeft: "-12px",
								marginTop: "-12px",
							}}
						/>
					)}
					{loading ? "Збереження..." : "Зберегти"}
				</MuiButton>
			</Box>
		</Modal>
	);
};
