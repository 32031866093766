import { Box } from "@mui/material";
import { FC } from "react";
import { DeleteButton, EditButton, DeleteWithConfirmButton } from "react-admin";

const PreferenceButtons: FC<{
  label: string;
  disableEdit?: boolean;
  disableDelete?: boolean;
}> = ({ label, disableEdit, disableDelete }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="start"
      flexDirection="column"
      gap={2}
    >
      <Box display="flex" alignItems="start" flexDirection="column" gap={"4px"}>
        {disableEdit ? <></> : <EditButton label="Редагувати" />}
        {disableDelete ? <></> : <DeleteButton label="Видалити" />}
        {/* {disableDelete ? <></> : <DeleteWithConfirmButton label="Видалити" />} */}
      </Box>
    </Box>
  );
};

export default PreferenceButtons;
