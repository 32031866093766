import { useContext, useEffect, useState } from "react";
import { Admin, Resource } from "react-admin";

import { createHashHistory } from "history";

import Spinner from "../../components/common/Spinner";

import authProvider from "../../providers/authProvider";
import { customBuildFields } from "../../providers/dataProvider";

import buildHasuraProvider from "ra-data-hasura";

import CustomLayout from "../../components/common/MenuLayout/MenuLayout";

import apolloClient from "../../lib/apollo";

import Dashboard from "../dashboard";

import { AccessContext } from "../../containers/access-provider";

import { AnalyticsList } from "../analytics";
import { BanRequestsEdit, BanRequestsList } from "../banRequests";
import { CoachRequestsEdit, CoachRequestsList } from "../coachRequests";
import {
  ConnectGroupCreate,
  ConnectGroupEdit,
  ConnectGroupList,
} from "../connectGroups";
import { ConnectRequestsEdit, ConnectRequestsList } from "../connectRequests";
import EventEdit from "../events/EventEdit";
import EventsCreate from "../events/EventsCreate";
import EventsList from "../events/EventsList.tsx";
import { FiltersCreate, FiltersEdit, FiltersList } from "../filters";
import FoundationsList from "../foundations/FoundationsList";
import { InfostandList } from "../infostand";
import { MeetingsCreate, MeetingsEdit, MeetingsList } from "../meetings";
import {
  MeetingsRequestsEdit,
  MeetingsRequestsList,
} from "../meetingsRequests";
import { PrayerWallEdit, PrayerWallList } from "../prayerWall";
import { RolesCreate, RolesEdit, RolesList } from "../roles";
import {
  RolesNamesCreate,
  RolesNamesEdit,
  RolesNamesList,
} from "../rolesNames";
import { UsersCreate, UsersEdit, UsersList } from "../users";
import {
  WhiteListWordsCreate,
  WhiteListWordsEdit,
  WhiteListWordsList,
} from "../whiteListWords";
import { VideosCreate, VideosEdit, VideosList } from "../videos";
import { VolontierEdit, VolontierGroupsList } from "../volontierGroup";
import FoundationsCreate from "../foundations/FoundationsCreate";
import FoundationsEdit from "../foundations/FoundationsEdit";
import VolunteerTeamsCreate from "../volunteer_teams/VolunteerTeamsCreate";
import VolunteerTeamsEdit from "../volunteer_teams/VolunteerTeamsEdit";
import VolunteerTeamsList from "../volunteer_teams/VolunteerTeamsList";
import AccessLevelList from "../accessLevel/AccessLevelList";
import AccessLevelEdit from "../accessLevel/AccessLevelEdit";
import AccessLevelCreate from "../accessLevel/AccessLevelCreate";
import { FaqEdit, FaqList } from "../faq";

const history = createHashHistory();

const AdminPanel = () => {
  const { accesses } = useContext(AccessContext);
  const [dataProvider, setDataProvider] = useState(null);
  //console.log("accesses from AdminPanel: ", accesses);

  useEffect(() => {
    const buildDataProvider = async () => {
      const dataProvider = await buildHasuraProvider(
        {
          client: apolloClient,
        },
        { buildFields: customBuildFields }
      );
      setDataProvider(() => dataProvider);
    };
    buildDataProvider();
  }, []);

  if (!dataProvider) {
    return <Spinner size={60} />;
  }

  const usersProps =
    accesses["users"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };
  const whiteListWordsProps =
    accesses["white_list_words"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };
  const foundationsProps =
    accesses["foundations"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };
  const eventsProps =
    accesses["events"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };
  const videosProps =
    accesses["videos"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };
  const filtersProps =
    accesses["filters"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };
  const connectProps =
    accesses["connect_group"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };
  const connectListProps =
    accesses["connect_group_list"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };
  const volontierProps =
    accesses["volontier_group"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };
  const meetingsProps =
    accesses["meetings"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };
  const prayersProps =
    accesses["prayers"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };
  const coachAlertsProps =
    accesses["alerts_to_coach"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };
  const rolesProps =
    accesses["roles"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };
  const rolesNamesProps =
    accesses["roles_names"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };
  const infostandProps =
    accesses["infostand"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };
  const analyticsProps =
    accesses["analytics"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };
  const meetingsRequestsProps =
    accesses["meetings_requests"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };
  const banRequestsProps =
    accesses["ban_requests"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };

  const volunteerTeamsProps =
    accesses["volunteer_teams"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };

  const accessLevelProps =
    accesses["access_level"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };
  const faqProps =
    accesses["faq"] || accesses["all"]
      ? {}
      : { list: <></>, edit: <></>, create: <></> };

  return (
    <Admin
      loginPage={false}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={CustomLayout}
      history={history}
    >
      <Resource
        name="users"
        list={UsersList}
        edit={UsersEdit}
        create={UsersCreate}
        {...usersProps}
      />
      <Resource
        name="white_list_words"
        list={WhiteListWordsList}
        edit={WhiteListWordsEdit}
        create={WhiteListWordsCreate}
        {...whiteListWordsProps}
      />
      <Resource
        name="access_level"
        list={AccessLevelList}
        edit={AccessLevelEdit}
        create={AccessLevelCreate}
        {...accessLevelProps}
      />
      <Resource
        name="events"
        list={EventsList}
        edit={EventEdit}
        create={EventsCreate}
        {...eventsProps}
      />
      <Resource
        name="foundations"
        list={FoundationsList}
        edit={FoundationsEdit}
        create={FoundationsCreate}
        {...foundationsProps}
      />

      <Resource
        name="videos"
        list={VideosList}
        edit={VideosEdit}
        create={VideosCreate}
        {...videosProps}
      />

      <Resource
        name="volunteer_teams"
        list={VolunteerTeamsList}
        edit={VolunteerTeamsEdit}
        create={VolunteerTeamsCreate}
        {...volunteerTeamsProps}
      />

      <Resource
        name="filters"
        list={FiltersList}
        edit={FiltersEdit}
        create={FiltersCreate}
        recordRepresentation="name_ua"
        {...filtersProps}
      />
      <Resource
        name="connect_group"
        list={ConnectRequestsList}
        edit={ConnectRequestsEdit}
        {...connectProps}
      />
      <Resource
        name="connect_group_list"
        list={ConnectGroupList}
        edit={ConnectGroupEdit}
        create={ConnectGroupCreate}
        {...connectListProps}
      />
      <Resource
        name="volontier_group"
        list={VolontierGroupsList}
        edit={VolontierEdit}
        {...volontierProps}
      />
      <Resource
        name="meetings"
        list={MeetingsList}
        edit={MeetingsEdit}
        create={MeetingsCreate}
        {...meetingsProps}
      />
      <Resource
        name="prayers"
        list={PrayerWallList}
        edit={PrayerWallEdit}
        {...prayersProps}
      />
      <Resource
        name="alerts_to_coach"
        list={CoachRequestsList}
        edit={CoachRequestsEdit}
        {...coachAlertsProps}
      />
      <Resource
        name="roles"
        list={RolesList}
        edit={RolesEdit}
        create={RolesCreate}
        {...rolesProps}
      />
      <Resource
        name="roles_names"
        list={RolesNamesList}
        edit={RolesNamesEdit}
        create={RolesNamesCreate}
        {...rolesNamesProps}
      />
      <Resource name="infostand" list={InfostandList} {...infostandProps} />
      <Resource name="analytics" list={AnalyticsList} {...analyticsProps} />
      <Resource
        name="meetings_requests"
        list={MeetingsRequestsList}
        edit={MeetingsRequestsEdit}
        {...meetingsRequestsProps}
      />
      <Resource
        name="ban_requests"
        list={BanRequestsList}
        edit={BanRequestsEdit}
        {...banRequestsProps}
      />
      <Resource name="faq" {...faqProps} list={FaqList} edit={FaqEdit} />
    </Admin>
  );
};

export default AdminPanel;
