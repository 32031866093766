import { Stack } from "@mui/material";
import { FC, useState } from "react";
import {
  Button,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  ExportButton,
  FilterButton,
  FilterForm,
  FunctionField,
  ImageField,
  List,
  SelectArrayInput,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  UrlField,
  required,
  useRecordContext,
} from "react-admin";

import { Box } from "@mui/system";
import BulkActionButtons from "../../components/common/BulkActionButtons";
import PreferenceButtons from "../../components/common/PreferenceButtons";

const postFilters = [
  <TextInput label="За словом" source="word" />,
  <TextInput label="За описом" source="description" />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton className="filter" filters={postFilters} />
    <CreateButton label="Створити" />
  </TopToolbar>
);

const TagFilter = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="end">
    <FilterForm filters={postFilters} />
  </Stack>
);

const WhiteListWordsList = () => {
  return (
    <List
      title="Дозволені слова"
      sx={{ tableLayout: "fixed" }}
      actions={<ListActions />}
      filters={<TagFilter />}
      sort={{ field: "created_at", order: "ASC" }}
    >
      <Datagrid
        rowClick="edit"
        bulkActionButtons={<BulkActionButtons />}
        sx={{
          width: "100%",
          backgroundColor: "#f5f5f5",
        }}
      >
        <TextField label="Слово" source="word" />
        <DateField label="Дата завантаження" source="created_at" showTime />
        <TextField label="Опис" source="description" />
        <PreferenceButtons label="Налаштування" />
      </Datagrid>
    </List>
  );
};

export default WhiteListWordsList;
