import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {videosReducer} from "./slices/videosSlice";
import {userRequestsReducer} from "./slices/userRequestsSlice";

const rootReducer = combineReducers({
	videos: videosReducer,
	userRequests: userRequestsReducer,
});

export const store = configureStore({
	reducer: rootReducer,
	devTools: process.env.NODE_ENV !== 'production',
});


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
