import { FC, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  BooleanInput,
  Button,
  DateTimeInput,
  Edit,
  ImageField,
  NumberInput,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useGetList,
  useNotify,
  useRecordContext,
  useRedirect,
  useUpdate,
} from "react-admin";

import { Box } from "@mui/material";

const CustomToolBar: FC<{}> = ({}) => {
  const form = useFormContext();
  const notify = useNotify();
  const [update, { isLoading }] = useUpdate();
  const redirect = useRedirect();

  const SaveHandler = async () => {
    try {
      const data = form.getValues();
      //console.log("data from SaveHandler: ", data);

      const { id, word, description } = data;
      delete data.id;

      if (!word) {
        notify("Введіть слово", { type: "error" });
        return;
      } else if (!description) {
        notify("Введіть опис", { type: "error" });
        return;
      }

      if (data) {
        await update("white_list_words", { id, data });
        redirect("/white_list_words");
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      <Button
        size="medium"
        disabled={isLoading}
        label="Зберегти"
        onClick={SaveHandler}
      />
    </Toolbar>
  );
};

const WhiteListWordsEdit = () => {
  return (
    <Edit title="Редагувати слово" sx={{ tableLayout: "fixed", width: "100%" }}>
      <SimpleForm toolbar={<CustomToolBar />}>
        <Box display="flex" flexDirection="column" width="100%" sx={{ mt: 3 }}>
          <TextInput
            sx={{ width: "100%" }}
            multiline
            label="Слово"
            validate={required()}
            source="word"
          />
          <TextInput
            multiline
            sx={{ width: "100%" }}
            label="Опис"
            source="description"
            validate={required()}
          />
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default WhiteListWordsEdit;
