import { SelectInput, required } from 'react-admin';
import { countries } from '../../../lib/countries';

const CountryInput = ({fullWidth = false}) => {
  return (
    <SelectInput
      fullWidth={fullWidth}
      label="Країна"
      source="country"
      choices={countries}
      validate={required()}
    />
  );
};

export default CountryInput;
