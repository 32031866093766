import {Alert, Box, Button as MuiButton, Typography} from '@mui/material'
import {FC, useRef, useState} from 'react'
import {
	BooleanInput,
	Button,
	Edit,
	ImageField,
	SimpleForm,
	TextInput,
	Toolbar,
	useRecordContext,
	useRedirect,
	useUpdate,
} from 'react-admin'
import {useFormContext} from 'react-hook-form'
import {UploadVideoSnippet, download, storageRef} from '../../lib/firebase'
import {ImageCropperModal} from "../../components/common/ImageCropperModal/ImageCropperModal";

const CustomToolBar: FC<{
	blob: Blob | null
}> = ({blob}) => {
	const form = useFormContext()
	// const notify = useNotify();
	const [update, {isLoading}] = useUpdate()
	const redirect = useRedirect()

	const SaveHandler = async () => {
		try {
			const data = form.getValues()
			const {id} = data
			delete data.id

			console.log(data)

			const generateBannerString = () => {
				const id = Math.random().toString(36).substring(2, 12)
				return `Banner${id}`
			}

			if (blob) {
				const snapshot = await UploadVideoSnippet(
					data.title.replace(/\s/g, '') || generateBannerString(),
					blob
				)
				data.cover = await download(storageRef(snapshot.metadata.fullPath))
			}

			if (data) {
				await update('foundations', {id, data})
				redirect('/foundations')
				return
			}

			await update('foundations', {id, data})
			redirect('/foundations')
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
			<Button
				size='medium'
				disabled={isLoading}
				label='Зберегти'
				onClick={SaveHandler}
			/>
		</Toolbar>
	)
}

const EventTitle = () => {
	const record = useRecordContext()
	return <span> {record?.title} </span>
}

const FoundationsEdit = () => {
	const [open, setOpen] = useState(false);
	const [blob, setBlob] = useState<Blob | null>(null)
	const [blobError, setBlobError] = useState<string>('')

	const ImageInputRef = useRef<HTMLInputElement>(null)

	const handleUpload = (event: any) => {
		setBlob(null);
		setBlobError('');

		const img = new Image()
		img.src = URL.createObjectURL(event.target.files[0])

		img.onload = () => {
			setBlobError('')
			setOpen(true)
			setBlob(event.target.files[0])
		}
	}

	return (
		<Edit title={<EventTitle/>}>
			<SimpleForm toolbar={<CustomToolBar blob={blob}/>}>
				<Box display='flex' flex='row' gap={5} width='100%'>
					<TextInput
						multiline
						fullWidth
						label='Назва Українською'
						source='title'
					/>
					<TextInput
						multiline
						fullWidth
						label='Назва Англійською'
						source='title_en'
					/>
				</Box>
				<Box display='flex' flex='row' gap={5} width='100%'>
					<TextInput
						multiline
						fullWidth
						label='Опис Українською'
						source='description'
					/>
					<TextInput
						multiline
						fullWidth
						label='Опис Англійською'
						source='description_en'
					/>
				</Box>

				<BooleanInput label='Показується' source='shown'/>
				<BooleanInput label='Темний текст' source='dark_text'/>

				<Box display='flex' flexDirection='row' width='100%'>
					<TextInput fullWidth label='Посилання' source='link'/>
				</Box>

				<Box
					display='flex'
					flexDirection='row'
					width='50%'
					sx={{mt: 3}}
					alignItems='center'
				>
					<Box>
						<input
							type='file'
							accept='image/*'
							style={{display: 'none'}}
							ref={ImageInputRef}
							onChange={handleUpload}
						/>

						<Box sx={{width: '70%'}}>
							{blob ? (
								<ImageCropperModal open={open} setOpen={setOpen} selectedFile={blob} setBlob={setBlob}/>
							) : (
								<ImageField
									sx={{
										'& .RaImageField-image': {
											margin: 0,
											width: '100%',
											height: '100%',
										},
									}}
									title='video-snippet'
									source='cover'
								/>
							)}

							{blob && <img
								style={{width: '100%', height: '100%'}}
								src={URL.createObjectURL(blob)}
								alt={''}
							/>}

							{blobError && (
								<Alert severity='error' sx={{mb: 2}}>
									{blobError}
								</Alert>
							)}

							<Box
								display={'flex'}
								flexDirection={'row'}
								alignItems={'center'}
								justifyContent={'space-between'}
								gap={2}
							>
								<MuiButton
									variant='contained'
									sx={{
										background: '#703eff',
										lineHeight: '18px',
										'&:hover': {
											background: '#703eff',
										},
									}}
									onClick={() => {
										if (ImageInputRef.current) {
											ImageInputRef.current.click()
										}
									}}
								>
									Змінити картинку
								</MuiButton>
							</Box>
						</Box>
					</Box>
				</Box>
			</SimpleForm>
		</Edit>
	)
}

export default FoundationsEdit
