import { Stack } from "@mui/material";
import { FC, useState } from "react";
import {
  Button,
  CreateButton,
  Datagrid,
  DateInput,
  ExportButton,
  FilterButton,
  FilterForm,
  FunctionField,
  ImageField,
  List,
  SelectArrayInput,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  UrlField,
  required,
  useRecordContext,
} from "react-admin";

import { Box } from "@mui/system";
import BulkActionButtons from "../../components/common/BulkActionButtons";
import PreferenceButtons from "../../components/common/PreferenceButtons";
import VideoStatusField from "../../components/common/VideoStatusField";
import VideoShownInput from "../../components/common/inputs/VideoShownInput";

const postFilters = [
  <TextInput label="За посиланням" source="url" />,
  <TextInput label="За назвою" source="title" />,
  <TextInput label="За описом" source="description" />,
  <TextInput label="За тривалістю" source="duration" />,
  <DateInput label="За датою створення" source="created_date" />,
  <SelectInput
    label="За показом"
    source="shown"
    validate={required()}
    choices={[
      { id: true, name: "Показується" },
      { id: false, name: "Не показується" },
    ]}
  />,
  <SelectArrayInput
    label="За типом"
    source="status"
    choices={[
      { id: "archive", name: "Запис" },
      { id: "upcoming", name: "Заплановано" },
      { id: "live", name: "Эфір" },
      { id: "banner", name: "Баннер" },
    ]}
  />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton className="filter" filters={postFilters} />
    <CreateButton label="Створити" />
    <ExportButton label="Експорт" />
  </TopToolbar>
);

const TagFilter = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="end">
    <FilterForm filters={postFilters} />
  </Stack>
);

const DescriptionField: FC<{ label: string }> = ({ label }) => {
  const { description } = useRecordContext();

  const descriptionText = description || "";

  const [text, setText] = useState(
    descriptionText.length < 100 ? descriptionText : `${descriptionText.slice(0, 100)}...`
  );
  const [pressed, setPressed] = useState(false);

  return (
    <FunctionField
      label={label}
      render={() => (
        <Box display="flex" flexDirection="column">
          <span> {text} </span>
          {descriptionText.length >= 100 && (
            <Box display="flex" alignItems="flex-start">
              <Button
                sx={{ mt: 1 }}
                label={pressed ? "Сховати" : "Більше"}
                onClick={() => {
                  setPressed(!pressed);
                  setText(
                    pressed ? descriptionText : `${descriptionText.slice(0, 100)}...`
                  );
                }}
              />
            </Box>
          )}
        </Box>
      )}
    />
  );
};

const VideosList = () => {
  return (
    <List
      title="Ефіри та записи"
      sx={{ tableLayout: "fixed" }}
      actions={<ListActions />}
      filters={<TagFilter />}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid
        bulkActionButtons={<BulkActionButtons />}
        sx={{
          width: "100%",
          backgroundColor: "#f5f5f5",
        }}
      >
        <VideoShownInput label="Показується" />
        <VideoStatusField label="Тип" />

        <ImageField label="Фото" source="snippet" />
        <ImageField label="Фото EN" source="snippet_en" />
        <UrlField label="URL" source="url" />
        <TextField label="Позиція" source="position" />

        <TextField label="Назва" source="title" />
        <DescriptionField label="Опис" />
        <PreferenceButtons label="Налаштування" />
      </Datagrid>
    </List>
  );
};

export default VideosList;
