import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type VideosStoreProps = {
	donationStatus: boolean;
}

const initialState: VideosStoreProps = {
	donationStatus: false,
};

export const videosSlice = createSlice({
	name: "videos",
	initialState,
	reducers: {
		setDonationStatus: (state, action: PayloadAction<boolean>) => {
			state.donationStatus = action.payload;
		},
	},
});
export const {actions: videosActions, reducer: videosReducer} = videosSlice;
