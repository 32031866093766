/* eslint-disable react-hooks/rules-of-hooks */
import {
	arrayRemove,
	arrayUnion,
	collection,
	doc,
	getDoc,
	orderBy,
	query,
	serverTimestamp,
	updateDoc,
	where,
} from 'firebase/firestore'
import { useEffect, useState } from 'react'
import {
	DateInput,
	DeleteButton,
	Edit,
	SaveButton,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
	useDataProvider,
	useRecordContext,
	useRedirect,
	useUpdate,
} from 'react-admin'
import { db } from '../../lib/firebase'

import Box from '@mui/material/Box'
import { useFormContext } from 'react-hook-form'
import RequestStatusInput from '../../components/common/inputs/RequestStatusInput'
import { useCollectionQuery } from '../../hooks/useCollectionQuery'
import {useActions} from "../../store/storeHooks";
import {getAmountOfRequests} from "../../utils/getAmountOfRequests";

const Title = () => {
	const record = useRecordContext()
	console.log('record', record)
	return <span> {record?.name} </span>
}

const handleRejectedStatus = async (group_id, user_id, update, chats) => {
	chats.map(async ({ id }) => {
		// Видаляємо з групи юзерів
		if (id === group_id) {
			await updateDoc(doc(db, 'conversations', group_id), {
				users: arrayRemove(user_id),
				updatedAt: serverTimestamp(),
			})
		}

		// Видаляємо всі чати з юзерами
		if (id !== group_id) {
			await updateDoc(doc(db, 'conversations', id), {
				// groupId: null,
				isVisible: false,
				// users: arrayRemove(user_id),
				updatedAt: serverTimestamp(),
			})
		}
	})

	// Update connect_id in Hasura DB
	await update('users', {
		id: user_id,
		data: { connect_id: null },
	})
	return
}

const handleAcceptedStatus = async (
	oldGroupId,
	newGroupId,
	user_id,
	update,
	coachId,
	chats
) => {
	console.log('oldGroupId', oldGroupId, 'newGroupId', newGroupId)

	// Видаляємо зі старої групи юзера
	if (oldGroupId) {
		const groupDocRef = doc(db, 'conversations', oldGroupId)
		const groupDocSnap = await getDoc(groupDocRef)

		if (groupDocSnap.exists()) {
			await updateDoc(groupDocRef, {
				users: arrayRemove(user_id),
				updatedAt: serverTimestamp(),
			})
		}
	}

	if (newGroupId === null) {
		// Update connect_id in Hasura DB
		await update('users', {
		id: user_id,
			data: {
				connect_id: null },
		})
	}

	if (oldGroupId !== newGroupId) {
		// Update connect_id in Hasura DB
		await update('users', {
      id: user_id,
			data: {
		    connect_id: newGroupId
			},
		})

		// Встановлюємо users - [] всім іншим чатам з юзерами якщо юзер змінив групу
		chats.map(async ({ id, users, groupId, isVisible }) => {
			const userDocRef = doc(db, 'conversations', id)
			const userDocSnap = await getDoc(userDocRef)
			const isNotGroup = id !== newGroupId && id !== oldGroupId;

			if (
				userDocSnap.exists() &&
				isVisible &&
				isNotGroup
			) {
			  console.log("REMOVE", user_id)
				await updateDoc(userDocRef, {
				  isVisible: false,
					// users: [],
					// users: arrayRemove(user_id),
					updatedAt: serverTimestamp(),
				})
			}

			if(
			userDocSnap.exists() &&
			!isVisible  && isNotGroup) {
	     console.log("UNION", user_id, newGroupId)
  			await updateDoc(userDocRef, {
  	       isVisible: true,
  				// users: arrayUnion(user_id),
  				updatedAt: serverTimestamp(),
  			})
			}
		})

		if(newGroupId) {
    		// Добавляємо юзера в нову групу
    		await updateDoc(doc(db, 'conversations', newGroupId), {
    			users: arrayUnion(user_id),
    			updatedAt: serverTimestamp(),
    		})
		}
	}
}

const CustomToolBar = () => {
	const { group_id, user_id } = useRecordContext()
	const { getValues } = useFormContext()
	const [update] = useUpdate(undefined, undefined, {
		onSuccess(data, variables, context) {},
	})
	const redirect = useRedirect()

	const [connectGroupList, setConnectGroupList] = useState([])
	const dataProvider = useDataProvider()

	// Дістаємо всі чати де юзер є у списку
	const { data, error } = useCollectionQuery(
		'conversations',
		query(
			collection(db, 'conversations'),
			orderBy('updatedAt', 'desc'),
			where('users', 'array-contains', user_id)
		)
	)

	// Дістаємо всі групи в зручному форматі
	const chats = data?.docs.map(item => ({
		id: item.id,
		...item.data(),
	}))

	useEffect(() => {
		dataProvider
			.getList('connect_group_list', {})
			.then(({ data }) => {
				const array = []
				console.log('data', data)

				for (const element of data) {
					array.push({
						id: element.id,
						name: element.name,
						coachId: element.main_coach_id,
					})
				}

				setConnectGroupList(array)
			})
			.catch(error => {
				console.log(error)
			})
	}, [])

	console.log('chats', chats)

	const { setConnectGroupRequests} = useActions();

	const onSave = async () => {
		// const { group_id: group_id_form, status } = getValues()
		setTimeout(() => {
			dataProvider.getList('connect_group', {
				filter: {},
			}).then(res => setConnectGroupRequests(getAmountOfRequests(res.data)))
		}, 200)

		const values = getValues()

		console.log('values', values)

		const isRejected = values.status === 'rejected'
		const isAccepted = values.status === 'accepted'

		if (isRejected) {
			await handleRejectedStatus(group_id, user_id, update, chats)
		}

		if (isAccepted) {
			await handleAcceptedStatus(
				group_id,
				values.group_id,
				user_id,
				update,
				connectGroupList[0].coachId,
				chats
			)
		}

		await update('connect_group', { id: values.id, data: {
		  ...values,
		  joined: values.joined && values.group_id === group_id ? values.joined : new Date()
		}})
		redirect('/connect_group')
	}

	return (
		<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
			<SaveButton label='Зберегти'  onClick={onSave} />
			<DeleteButton label='Видалити' />
		</Toolbar>
	)
}

const ConnectGroupInput = () => {
	const [connectGroupList, setConnectGroupList] = useState([])
	const dataProvider = useDataProvider()

	useEffect(() => {
		dataProvider
			.getList('connect_group_list', {})
			.then(({ data }) => {
				const array = []
				console.log('data', data)

				for (const element of data) {
					array.push({ id: element.id, name: element.name })
				}

				setConnectGroupList(array)
			})
			.catch(error => {
				console.log(error)
			})
	}, [])

	return (
		<SelectInput
			sx={{ ml: 3 }}
			name='group_id'
			label='Конект група'
			source='group_id'
			choices={connectGroupList}
		/>
	)
}

const ConnectRequestsEdit = () => {
	return (
		<Edit mutationMode="optimistic" title={<Title />}>
			<SimpleForm toolbar={<CustomToolBar />}>
				<Box display='flex' flexDirection='column'>
					<Box display='flex' flex='row' width='100%'>
						<DateInput
							sx={{ ml: 3 }}
							label='Відправлено'
							disabled
							source='created_at'
						/>
					</Box>
					<Box display='flex' flex='row' width='100%'>
						<TextInput sx={{ ml: 3 }} label='ПІБ' disabled source='name' />
						<TextInput sx={{ ml: 3 }} label='Пошта' disabled source='email' />
						<TextInput sx={{ ml: 3 }} label='Телефон' disabled source='phone' />
					</Box>
					<Box display='flex' flex='row' width='100%'>
						<TextInput
							sx={{ ml: 3 }}
							label='Країна'
							disabled
							source='country'
						/>
						<TextInput sx={{ ml: 3 }} label='Місто' disabled source='region' />
					</Box>
					<Box display='flex' flex='row' width='100%'>
						<RequestStatusInput />
						<ConnectGroupInput />
					</Box>
				</Box>
			</SimpleForm>
		</Edit>
	)
}

export default ConnectRequestsEdit
