import { FC, Fragment, useRef, useState } from "react";
import {
  Button,
  Create,
  required,
  SimpleForm,
  TextInput,
  Toolbar,
  useCreate,
  useDataProvider,
  useGetList,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import Box from "@mui/material/Box";

const CustomToolBar: FC<{}> = () => {
  const form = useFormContext();
  const notify = useNotify();
  const [create, { isLoading }] = useCreate(undefined, undefined, {});

  const redirect = useRedirect();

  const dataProvider = useDataProvider();

  const SaveHandler = async () => {
    try {
      const values = form.getValues();
      //console.log("values from CustomToolBar:", values);

      const data = {
        word: values.word,
        description: values.description,
      };

      if (!data.word) {
        notify("Введіть слово", { type: "error" });
        return;
      } else if (!data.description) {
        notify("Введіть опис", { type: "error" });
        return;
      }

      await create("white_list_words", {
        data,
      });

      notify("Запис створено", { type: "info" });

      //the purpose of this is just for the list to be updated then the user is redirected to it:
      const { data: updatedList } = await dataProvider.getList(
        "white_list_words",
        {
          pagination: { page: 1, perPage: 10 },
          sort: { field: "id", order: "ASC" },
          filter: {},
        }
      );
      // console.log("updatedList from CustomToolBar:", updatedList);

      redirect("/white_list_words");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      <Button
        size="medium"
        disabled={isLoading}
        label="Зберегти"
        onClick={SaveHandler}
      />
    </Toolbar>
  );
};

const WhiteListWordsCreate = () => {
  return (
    <Create title="Створити нове доволене слово" sx={{ tableLayout: "fixed" }}>
      <SimpleForm toolbar={<CustomToolBar />}>
        <Box display="flex" flexDirection="column" width="100%" sx={{ mt: 3 }}>
          <TextInput
            sx={{ width: "100%" }}
            multiline
            label="Слово"
            validate={required()}
            source="word"
          />
          <TextInput
            multiline
            sx={{ width: "100%" }}
            label="Опис"
            source="description"
            validate={required()}
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};

export default WhiteListWordsCreate;
