export const REACT_APP_IS_DEVELOPMENT =
  process.env.REACT_APP_IS_DEVELOPMENT === "true";

export const REACT_APP_GRAPHQL_API_URL = REACT_APP_IS_DEVELOPMENT
  ? "https://dev-hillsong.hasura.app/v1/graphql"
  : "https://knowing-collie-86.hasura.app/v1/graphql";

export const REACT_APP_GRAPHQL_WS_API_URL = REACT_APP_IS_DEVELOPMENT
  ? "wss://dev-hillsong.hasura.app/v1/graphql"
  : "wss://knowing-collie-86.hasura.app/v1/graphql";

export const REACT_APP_HASURA_ADMIN_SECRET = REACT_APP_IS_DEVELOPMENT
  ? "urlR9QHkllshppAJOLumqflorkgZaNoRI3kcAYYd6riBop3NTq5XCQGzX8JtWoRO"
  : "MlWbFWVs1IeKwj0CUC2fA3zBVb8gv4EEaJX4mw5xeDwWogUEZ7t8uwbbxatCfDZV";

export const REACT_APP_API_KEY = "AIzaSyB7syje-hwZzrHpySuLOMXHKR0cng9-Cm4";
export const REACT_APP_AUTH_DOMAIN = "hillsong-559ec.firebaseapp.com";
export const REACT_APP_DATABASE_URL = "https://hillsong-559ec.firebaseapp.com";
export const REACT_APP_PROJECT_ID = "hillsong-559ec";
export const REACT_APP_STORAGE_BUCKET = "hillsong-559ec.appspot.com";
export const REACT_APP_MESSAGE_SENDER_ID = "170213575475";

// Independent constants
export const REACT_APP_APP_ID = "1:170213575475:web:4848f39ab7c98525cdcc46";
export const REACT_APP_ADMIN_EMAIL = "admin@hillsong.com.ua";
export const REACT_APP_ADMIN_PASSWORD = "Gwjovg_vegq14!";
export const REACT_APP_YOUTUBE_KEY = "AIzaSyCS83t3TlP3Dpvc52ZISEkUPU-0CQOCDYw";
export const REACT_APP_MAPS_KEY = "AIzaSyB7SC3LFP6BaGPWe5hzzh5FD0VJw2elsqs";

//new media admin
export const REACT_APP_MEDIA_ADMIN_EMAIL = "media@hillsong.com.ua";
