import {useContext, useEffect, useState} from "react";
import {
	BooleanInput,
	Button,
	DateInput,
	DeleteButton,
	Edit,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
	required,
	useDataProvider,
	useRecordContext,
	useRedirect,
	useSaveContext,
	useUpdate,
} from "react-admin";

import Box from "@mui/material/Box";
import {
	arrayRemove,
	arrayUnion,
	collection,
	doc,
	getDoc,
	orderBy,
	query,
	serverTimestamp,
	updateDoc,
	where,
} from "firebase/firestore";
import {httpsCallable} from "firebase/functions";
import {useFormContext} from "react-hook-form";
import CoachInput from "../../components/common/inputs/CoachInput";
import CountryInput from "../../components/common/inputs/CountryInput";
import {AccessContext} from "../../containers/access-provider";
import {useCollectionQuery} from "../../hooks/useCollectionQuery";
import {db, fns} from "../../lib/firebase";
import {Tab} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";

const UserTitle = () => {
	const record = useRecordContext();
	return <span> {record?.name} </span>;
};

const CustomSaveButton = () => {
	const [saveLoading, setSaveLoading] = useState(null);

	const [update, {isLoading}] = useUpdate();
	const saveContext = useSaveContext();
	const {
		id: user_id,
		banned,
		connect_id,
		coach_connect_id,
		coach_id,
		access_level,
	} = useRecordContext();
	const form = useFormContext();
	const redirect = useRedirect();

	const [connectGroupList, setConnectGroupList] = useState([]);
	const dataProvider = useDataProvider();

	// Дістаємо всі чати де юзер є у списку
	const {data, error} = useCollectionQuery(
		"conversations",
		query(
			collection(db, "conversations"),
			orderBy("updatedAt", "desc"),
			where("users", "array-contains", user_id)
		)
	);

	// Дістаємо всі групи в зручному форматі
	const chats = data?.docs.map((item) => ({
		id: item.id,
		...item.data(),
	}));

	console.log("chats", chats);

	useEffect(() => {
		dataProvider
			.getList("connect_group_list", {})
			.then(({data}) => {
				const array = [];
				console.log("data", data);

				for (const element of data) {
					array.push({
						id: element.id,
						name: element.name,
						coachId: element.main_coach_id,
					});
				}

				setConnectGroupList(array);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const SaveHandler = async () => {
		setSaveLoading(true);
		const values = form.getValues();

		console.log("values", values);

		if (values.access_level !== access_level) {
			if (coach_connect_id) {
				const groupDocRef = doc(db, "conversations", coach_connect_id);
				const groupDocSnap = await getDoc(groupDocRef);
				await updateDoc(groupDocSnap, {
					coach: {id: null},
					group: {
						coachId: null,
						groupId: coach_connect_id,
					},
					users: arrayRemove(user_id), // remove user_id
					updatedAt: serverTimestamp(),
				});
			}

			// Встановлюємо isVisible: false, всім іншим чатам з лідером якщо лідер змінив групу
			chats.map(async (chat) => {
				const userDocRef = doc(db, "conversations", chat.id);
				const userDocSnap = await getDoc(userDocRef);

				if (userDocSnap.exists() && chat.id !== coach_connect_id) {
					await updateDoc(userDocRef, {
						isVisible: false,
						updatedAt: serverTimestamp(),
					});
				}
			});
		}

		if (connect_id !== values.connect_id) {
			// Видаляємо юзера зі старої групи
			chats.map(async ({id}) => {
				// Видаляємо з групи
				if (id === connect_id) {
					await updateDoc(doc(db, "conversations", connect_id), {
						users: arrayRemove(user_id),
						updatedAt: serverTimestamp(),
					});
				}

				// Видаляємо всі чати з юзерами
				if (id !== connect_id) {
					await updateDoc(doc(db, "conversations", id), {
						// users: [],
						isVisible: false,
						updatedAt: serverTimestamp(),
					});
				}
			});

			// Якщо юзер змінив групу, добавляємо в групу
			if (values.connect_id) {
				const groupDocRef = doc(db, "conversations", values.connect_id);
				const groupDocSnap = await getDoc(groupDocRef);

				if (groupDocSnap.exists()) {
					await updateDoc(groupDocRef, {
						users: arrayUnion(user_id),
						updatedAt: serverTimestamp(),
					});
				}
			}

			// Якщо юзеру видалили групу
			if (values.connect_id === null) {
				chats.map(async ({id}) => {
					// Видаляємо з групи
					if (id === connect_id) {
						await updateDoc(doc(db, "conversations", connect_id), {
							users: arrayRemove(user_id),
							updatedAt: serverTimestamp(),
						});
					}

					// Видаляємо всі чати з юзерами
					if (id !== connect_id) {
						await updateDoc(doc(db, "conversations", id), {
							// users: [],
							isVisible: false,
							updatedAt: serverTimestamp(),
						});
					}
				});
			}
		}

		if (banned !== values.banned) {
			const banUser = httpsCallable(fns, "banUser");
			const unbanUser = httpsCallable(fns, "unbanUser");
			values.banned
				? await banUser({uid: user_id})
				: await unbanUser({uid: user_id});
		}

		await update("users", {id: user_id, data: {...values}});
		setSaveLoading(false);
		redirect("/users");
	};

	return (
		<Button
			label="Зберегти"
			disabled={saveContext?.saving || isLoading || saveLoading}
			size="medium"
			onClick={SaveHandler}
		/>
	);
};

const CustomToolBar = () => {
	return (
		<Toolbar sx={{display: "flex", justifyContent: "space-between"}}>
			<CustomSaveButton label="Зберегти"/>
			<DeleteButton label="Видалити"/>
		</Toolbar>
	);
};

const RoleInput = ({fullWidth = false}) => {
	const [roles, setRoles] = useState([]);
	const {accesses} = useContext(AccessContext);
	const dataProvider = useDataProvider();

	useEffect(() => {
		dataProvider
			.getList("roles_names", {})
			.then(({data}) => {
				const array = [];

				for (const element of data) {
					array.push({id: element.id, name: element.name});
				}

				setRoles(array);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return accesses["roles"] || accesses["roles_names"] || accesses["all"] ? (
		<SelectInput fullWidth={fullWidth} label="Роль" source="role_id" choices={roles}/>
	) : (
		<></>
	);
};

const ConnectGroupInput = ({fullWidth = false}) => {
	const [connectGroups, setConnectGroups] = useState([]);

	const dataProvider = useDataProvider();

	useEffect(() => {
		dataProvider
			.getList("connect_group_list", {})
			.then(({data}) => {
				const array = [];

				for (const element of data) {
					array.push({id: element.id, name: element.name});
				}

				setConnectGroups(array);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<SelectInput
			fullWidth={fullWidth}
			label="Конект група"
			source="connect_id"
			choices={connectGroups}
		/>
	);
};

const UsersEdit = () => {
	const [value, setValue] = useState('1');

	const handleChange = (_, newValue) => {
		setValue(newValue);
	};

	return (
		<Edit title={<UserTitle/>}>
			<TabContext value={value}>
				<Box sx={{borderBottom: 1, borderColor: 'divider'}}>
					<TabList onChange={handleChange} aria-label="tabs example">
						<Tab label="Особисті дані" value="1"/>
						<Tab label="Рівень доступу" value="2"/>
						<Tab label="Спільнота" value="3"/>
					</TabList>
				</Box>
				<TabPanel value="1">
					<SimpleForm toolbar={<CustomToolBar/>}>
						<Box display="flex" flexDirection="column">
							<Box display="flex" flex="row" flexWrap="wrap" width="100%" maxWidth={544}>
								<TextInput fullWidth label="ПІБ" source="name" validate={required()}/>
								<DateInput
									fullWidth
									disabled
									label="Зареєстрован"
									source="created_at"
								/>
								<TextInput fullWidth disabled label="Пошта" source="email"/>
								<TextInput fullWidth label='Телефон' source='phone'/>
							</Box>
							<Box display="flex" flex="row" flexWrap="wrap" width="100%">
								<SelectInput
									fullWidth
									label="Стать"
									source="gender"
									emptyText="Бажаю не вказувати"
									choices={[
										{id: 'male', name: 'Чоловік'},
										{id: 'female', name: 'Жінка'},
									]}
								/>
							</Box>
							<Box display="flex" flex="row" flexWrap="wrap" width="100%">
								<CountryInput fullWidth/>
								<TextInput fullWidth label="Місто" source="region"/>
							</Box>
							<Box display="flex" flex="row" flexWrap="wrap" width="100%">
								<TextInput fullWidth label="Церква" source="church"/>
								<TextInput fullWidth disabled label="ID" source="id"/>
							</Box>
						</Box>
					</SimpleForm>
				</TabPanel>
				<TabPanel value="2">
					<SimpleForm toolbar={<CustomToolBar/>}>
						<Box display="flex" flexDirection="column" flexWrap="wrap">
							<Box display="flex" flexWrap="wrap" flex="row" alignItems="center" width="100%">
								<SelectInput
									fullWidth
									validate={required()}
									label="Рівень доступу"
									source="access_level"
									choices={[
										{id: 'user', name: 'Користувач'},
										{id: 'coach', name: 'Лідер'},
										{id: 'moderator', name: 'Модератор'},
									]}
								/>
								<RoleInput fullWidth/>
								<BooleanInput
									label="Забанено"
									source="banned"
								/>
							</Box>
						</Box>
					</SimpleForm>
				</TabPanel>
				<TabPanel value="3">
					<SimpleForm toolbar={<CustomToolBar/>}>
						<Box display="flex" flexDirection="column" width="100%" maxWidth="544px">
							<ConnectGroupInput fullWidth/>
						</Box>
					</SimpleForm>
				</TabPanel>
			</TabContext>
		</Edit>
	);
};

export default UsersEdit;
